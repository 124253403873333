<template>
	<div id="complete"></div>
</template>

<script>
import axios from 'axios'
export default {
	props: {
		lnglats: Array,
		homeLngLats: Array,
	},
	data() {
		return {
			map: null,
			marker1: null,
			marker2: null,
		}
	},
	methods: {
		async carGPSIP() {
			this.map = new AMap.Map('complete', {
				resizeEnable: true,
				zoom: 12,
				center: this.homeLngLats, //中心点坐标
			}) //初始化地图

			this.marker1 = new AMap.Marker({
				icon: new AMap.Icon({
					size: new AMap.Size(30, 30), // 图标尺寸
					image: require('../../../../../assets/imgs/kehu.png'), // Icon的图像
					imageSize: new AMap.Size(30, 30),
				}),
				position: this.homeLngLats, // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
				label: {
					content: `<div style=background-color:#409EFF;padding:${5}px;border:${1}px solid #409EFF>客户地址</div>`,
					offset: new AMap.Pixel(0, -1),
					direction: 'top',
				},
			})

			this.marker2 = new AMap.Marker({
				icon: new AMap.Icon({
					size: new AMap.Size(30, 30), // 图标尺寸
					image: require('../../../../../assets/imgs/qiandao.png'), // Icon的图像
					imageSize: new AMap.Size(30, 30),
				}),
				position: this.lnglats, // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
				label: {
					content: `<div style=background-color:#0AE761;padding:${5}px;border:${1}px solid #67C23A>签到地址</div>`,
					offset: new AMap.Pixel(0, -1),
					direction: 'top',
				},
			})

			this.map.add([this.marker1, this.marker2])
			let result1 = await axios({
				method: 'get',
				url: 'https://restapi.amap.com/v3/geocode/regeo',
				params: {
					key: 'a40dccd53b4b7be31839cebd738630ac',
					location: this.homeLngLats.join(','),
				},
			})
			let result2 = await axios({
				method: 'get',
				url: 'https://restapi.amap.com/v3/geocode/regeo',
				params: {
					key: 'a40dccd53b4b7be31839cebd738630ac',
					location: this.lnglats.join(','),
				},
			})

			AMap.event.addListener(this.marker1, 'click', (e) => {
				new AMap.InfoWindow({
					content: `<span style=font-size:10px>${result1.data.regeocode.formatted_address}</span>`,
					offset: new AMap.Pixel(0, -30),
				}).open(this.map, this.marker1.getPosition())
			})

			AMap.event.addListener(this.marker2, 'click', (e) => {
				new AMap.InfoWindow({
					content: `<span style=font-size:10px>${result2.data.regeocode.formatted_address}</span>`,
					offset: new AMap.Pixel(0, -30),
				}).open(this.map, this.marker2.getPosition())
			})

			this.map.setFitView()
		},
	},
	mounted() {
		this.carGPSIP()
	},
}
</script>

<style lang="scss" scoped>
#complete {
	width: 85%;
	height: 266.66px;
}

/deep/ .amap-marker-label {
	background-color: #409eff;
	border: 1px solid #fff;
	color: #ffffff;
	border-radius: 10%;
	padding: 0;
	//   padding: 0;
	//   .color {
	//     color: #fff;
	//     padding: 5px;
	//     border-radius: 5px;
	//     &.lightblue {
	//       background: lightblue;
	//     }
	//     &.blue {
	//       background: #7ac1ff;
	//     }
	//     &.red {
	//       background: #fc2121;
	//     }
	//   }
}
</style>
